import * as React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <main>
      <title>404</title>

      <div className="error-page">
        <h1>Ошибка 404</h1>
        <p>
          Кажется что-то пошло не так. Страница, которую вы запрашиваете, не
          существует. Возможно она устарела, была удалена, или был введен
          неверный адрес в адресной строке.
        </p>
        <Link to="/">
          <button className="button button__primary">Перейти на главную</button>
        </Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
